/**
 * Returns the date when the price is valid until, formatted as `YYYY-MM-DD`.
 * The date corresponds to the last day of the next quarter. If there is less than one month
 * remaining in the current quarter, it returns the last day of the following quarter.
 *
 * Example:
 * - If today is January 15th, it returns `2024-03-31`.
 * - If today is March 5th (less than one month before the end of Q1), it returns `2024-06-30`.
 *
 * @returns {string} ISO date string representing the last day of the valid quarter.
 */
export function getPriceValidUntil() {
  const now = new Date();
  const newMonth = Math.ceil((now.getMonth() + 2) / 3) * 3;
  const quarter = new Date(now.getFullYear(), newMonth, 0);
  return `${quarter.getFullYear()}-${String(quarter.getMonth() + 1).padStart(2, '0')}-${String(
    quarter.getDate(),
  ).padStart(2, '0')}`;
}
