export enum CatalogueOrderByType {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NEW_ASC = 'NEW_ASC',
  NEW_DESC = 'NEW_DESC',
  ON_STOCK = 'ON_STOCK',
  PRICE_ASC = 'PRICE_ASC',
  PRICE_DESC = 'PRICE_DESC',
  SALE_ASC = 'SALE_ASC',
  SALE_DESC = 'SALE_DESC',
  SELLING_PRODUCTS = 'SELLING_PRODUCTS',
  TOP_PRODUCTS = 'TOP_PRODUCTS',
}
