/**
 * Remove leading specific character (slash by default) from string
 * @param string - string to remove leading character
 * @param char - character to remove
 * @returns string
 */
export const removeLeadingChar = (string: string, char = '/') => {
  if (string.startsWith(char)) {
    return string.slice(1);
  }
  return string;
};
